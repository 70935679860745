<template>
  <div class="modal-overlay" v-if="isVisible" @click.self="close">
    <v-card class="modal-content">
      <v-btn icon class="close-button" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <div class="scrollable-content">
        <div v-if="mainImage" class="image-container">
          <img :src="mainImage" alt="POI Image" class="poi-image" />
        </div>

        <v-card-text>
          <p>
            <strong>Categories:</strong>
            {{ getCategoryNames(poi.properties.sub_categories).join(", ") }}
          </p>
          <p>
            <strong>Sub Categories:</strong>
            {{ getSubCategoryNames(poi.properties.sub_categories).join(", ") }}
          </p>
          <p v-if="tags.length">
            <strong>Tags:</strong> {{ getTags(tags).join(", ") }}
          </p>

          <div class="content-blocks">
            <div
              v-for="(block, index) in poi.properties.content_block"
              :key="index"
              class="content-block"
            >
              <div
                v-for="(content, contentIndex) in block.content_array"
                :key="contentIndex"
                class="content-card"
              >
                <p><strong>Description:</strong> {{ content.caption }}</p>
                <div
                  v-if="content.media_file && index !== 0"
                  class="additional-image-container"
                >
                  <img
                    :src="content.media_file"
                    alt="Additional POI Image"
                    class="additional-poi-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </div>

      <!-- Interaction Buttons -->
      <v-card-actions class="interaction-buttons">
        <v-btn
          @click="toggleLike"
          :color="liked ? 'primary' : 'grey'"
          icon
          class="like-button"
          :disabled="liked"
        >
          <v-icon>{{ liked ? "mdi-thumb-up" : "mdi-thumb-up-outline" }}</v-icon>
        </v-btn>
        <span>{{ likeText }}</span>

        <v-btn
          @click="toggleInterested"
          :color="interested ? 'black' : 'grey'"
          class="interested-button"
          :disabled="interested"
        >
          {{ interestedText }}
        </v-btn>

        <div class="rating">
          <label>Rating:</label>
          <div class="stars">
            <span
              v-for="star in 5"
              :key="star"
              :class="{ 'active-star': star <= rating }"
              @click="setRating(star)"
            >
              ★
            </span>
          </div>
          <div class="avg-rating-text">{{ avgRatingText }}</div>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { useDataStore } from "@/stores/dataStore";
import { toRaw } from "@vue/reactivity";
import categoriesMixin from "@/mixins/categoriesMixin";
import { defineComponent } from "vue";
import { s3Download } from "@/utils/fileDownload";
import { patchUsersMap } from "@/services";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "PoiDetail",
  mixins: [categoriesMixin],
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    poi: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const store = useDataStore();
    const route = useRoute();

    const getCategoryNames = (subCategoryIds) => {
      const rawCategoryMap = toRaw(store.categoryMap);
      const rawSubCategoryIds = toRaw(subCategoryIds);
      const categories = [];

      rawSubCategoryIds.forEach((id) => {
        const categoryName = rawCategoryMap[id] || `Unknown Category ID: ${id}`;
        if (!categories.includes(categoryName)) {
          categories.push(categoryName);
        }
      });

      return categories;
    };

    const getSubCategoryNames = (subCategoryIds) => {
      const rawSubCategoryMap = toRaw(store.subCategoryMap);
      const rawSubCategoryIds = toRaw(subCategoryIds);

      return rawSubCategoryIds.map(
        (id) => rawSubCategoryMap[id] || `Unknown Subcategory ID: ${id}`
      );
    };

    const getTags = (tags) => {
      return tags.map((tag) => tag.name || tag);
    };

    const close = () => {
      emit("close");
    };

    return {
      getCategoryNames,
      getSubCategoryNames,
      getTags,
      close,
      store,
      route,
    };
  },
  data() {
    return {
      mainImage: null,
      liked: false,
      interested: false,
      interestedText: "I'm Interested",
      likeText: "",
      avgRatingText: "",
      rating: 0,
    };
  },
  created() {
    this.fetchImages();
  },
  methods: {
    async fetchImages() {
      for (let block of this.poi.properties.content_block) {
        for (let content of block.content_array) {
          if (content.file_key) {
            try {
              const mediaFile = await s3Download(content.file_key);
              content.media_file = mediaFile;
              if (!this.mainImage) {
                this.mainImage = mediaFile;
              }
            } catch (err) {
              console.error("Failed to download image: ", err);
            }
          }
        }
      }
    },
    async toggleLike() {
      this.liked = true;

      try {
        const response = await this.patchPoiData({
          like: 1,
        });

        console.log("Response data:", response);

        const poiName = this.poi.name || this.poi.properties.name;
        console.log("POI Name being searched:", poiName);

        const updatedPoi = response.pois.find((poi) => poi.name === poiName);

        if (updatedPoi) {
          const likeCount = updatedPoi.num_likes;

          if (likeCount === 1) {
            this.likeText = "You're the first to like this";
          } else {
            this.likeText = `${likeCount} others liked this`;
          }
        } else {
          console.error("POI not found in response");
        }
      } catch (error) {
        console.error("Failed to toggle like status:", error);
        this.liked = false;
      }
    },
    async toggleInterested() {
      this.interested = true;

      try {
        const response = await this.patchPoiData({
          interested: 1,
        });

        console.log("Response data:", response);

        const poiName = this.poi.name || this.poi.properties.name;
        console.log("POI Name being searched:", poiName);

        const updatedPoi = response.pois.find((poi) => poi.name === poiName);

        if (updatedPoi) {
          const interestedCount = updatedPoi.num_interested;

          if (interestedCount === 1) {
            this.interestedText = "You're the first so far";
          } else {
            this.interestedText = `Hurry, ${interestedCount} others are interested too`;
          }
        } else {
          console.error("POI not found in response");
        }
      } catch (error) {
        console.error("Failed to toggle interested status:", error);
        this.interested = false;
      }
    },
    async setRating(star) {
      this.rating = star;

      try {
        const response = await this.patchPoiData({ rating: this.rating });

        const poiName = this.poi.name || this.poi.properties.name;
        console.log("POI Name being searched:", poiName);

        const updatedPoi = response.pois.find((poi) => poi.name === poiName);

        if (updatedPoi) {
          const avgRating = updatedPoi.avg_rating;
          const numRatings = updatedPoi.num_ratings;

          if (numRatings === 1) {
            this.avgRatingText = `Average Rating: ${avgRating.toFixed(
              2
            )} (1 rating)`;
          } else {
            this.avgRatingText = `Average Rating: ${avgRating.toFixed(
              2
            )} (${numRatings} ratings)`;
          }
        } else {
          console.error("POI not found in response");
        }
      } catch (error) {
        console.error("Failed to update rating:", error);
      }
    },
    async patchPoiData({ like, interested, rating }) {
      const mapId = this.route.params.id;

      const payload = {
        id_field: this.store.payload.id_field,
        map_json: {
          generic_data: {
            poi_level: [
              {
                name: this.poi.properties.name,
                bool_fields: [{ collected: true }],
                int_fields: [
                  { rating: rating || this.rating },
                  { interested: interested !== undefined ? interested : 0 },
                  { like: like !== undefined ? like : 0 },
                ],
              },
            ],
          },
        },
        org_domain_name: this.store.payload.org_domain_name,
        org_key: this.store.payload.org_key,
        pass_key: this.store.payload.pass_key,
        post_ready: true,
        user_supplied_email: this.store.payload.user_supplied_email,
        user_supplied_name: this.store.payload.user_supplied_name,
      };

      console.log("Final PATCH payload:", JSON.stringify(payload, null, 2));

      try {
        const response = await patchUsersMap(mapId, payload);
        console.log("POI data updated successfully:", response.data);
        return response.data;
      } catch (error) {
        console.error("Failed to update POI data:", error);
        if (error.response) {
          console.error("Server response data:", error.response.data);
        }
      }
    },
  },
});
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  max-width: 70vw;
  width: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.image-container {
  text-align: center;
  margin-bottom: 20px;
}

.poi-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.content-blocks {
  margin-top: 20px;
}

.content-block {
  margin-bottom: 20px;
}

.content-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.additional-image-container {
  text-align: center;
  margin-top: 10px;
}

.additional-poi-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.interaction-buttons {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background: white;
  border-top: 1px solid #ddd;
  z-index: 10;
}

.stars {
  display: flex;
}

.stars span {
  font-size: 24px;
  cursor: pointer;
  color: #ccc;
}

.stars .active-star {
  color: #ffc107;
}

.stars span:hover {
  color: #ffeb3b;
}
.v-btn__content {
  white-space: normal;
}
::v-deep .v-btn__content {
  white-space: normal !important;
}
.like-button {
  display: inline-flex;
  align-items: center;
}

.like-button + span {
  margin-left: 8px;
  font-size: 14px;
  color: #555;
}
@media (max-width: 600px) {
  .interaction-buttons {
    flex-direction: column;
    align-items: center;
  }
  .stars {
    margin-top: 10px;
  }

  .rating label {
    margin-right: 10px;
  }
  .rating {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .avg-rating-text {
    margin-left: 0;
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
  }
}
</style>
