import L from "leaflet";
import { useDataStore } from "@/stores/dataStore";

// Delete the default icon URL method and merge custom options for icons
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png"
});

export default {
  mounted() {
    this.initializeMap();
  },
  beforeUnmount() {
    this.destroyMap(); 
  },
  methods: {
    initializeMap() {
      const store = useDataStore();
      const defaultLatLng = [45.0918, -64.3665]; // Default to Wolfville

      // TODO: this causes issue with empty map upon navigation
      if (store.map) {
        return;
      }

      // Initialize the map with the default location
      store.map = L.map("map").setView(defaultLatLng, 13);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
      }).addTo(store.map);

      this.updateSearchMarker(defaultLatLng[0], defaultLatLng[1]);

      // Try to get user's location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLatLng = [
              position.coords.latitude,
              position.coords.longitude,
            ];
            store.map.setView(userLatLng, 13);
            this.updateSearchMarker(userLatLng[0], userLatLng[1]);
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      }
    },
    destroyMap() {
      const store = useDataStore();
      if (store.map) {
        store.map.remove();
        store.map = null; 
      }
    },
    updateMarkers() {
      const store = useDataStore();
      if (!store.map) return;

      // Remove existing markers
      store.markers.forEach((marker) => store.map.removeLayer(marker));
      store.markers = [];

      // Add new markers based on POIs
      store.pois.forEach((poi) => {
        if (poi.latitude && poi.longitude) {
          const marker = L.marker([poi.latitude, poi.longitude], {
            draggable: true,
          }).addTo(store.map);

          marker.bindTooltip("Item Location", {
            direction: "top", // Show tooltip above the marker
            className: "item-location-tooltip",
          });

          marker.on("dragend", (event) => {
            const { lat, lng } = event.target.getLatLng();
            poi.latitude = lat;
            poi.longitude = lng;
          });

          store.markers.push(marker);
        }
      });
    },
    updateSearchMarker(lat, lng) {
      const store = useDataStore();
      if (!store.map) return;

      if (store.searchMarker) {
        store.map.removeLayer(store.searchMarker);
      }

      // Create new search marker
      store.searchMarker = L.marker([lat, lng], {
        draggable: true,
      }).addTo(store.map);

      store.searchMarker.bindTooltip("Item Location", {
        direction: "top",
        className: "item-location-tooltip",
      });

      store.searchMarker.on("dragend", (event) => {
        const { lat, lng } = event.target.getLatLng();
        store.pois[0].latitude = lat;
        store.pois[0].longitude = lng;
      });

      // Set the initial coordinates
      store.pois[0].latitude = lat;
      store.pois[0].longitude = lng;
    },
    addMarkers(pois) {
      const store = useDataStore();
      if (!store.map) return;

      // Add markers for each POI
      pois.forEach(poi => {
        const latLng = [poi.latitude, poi.longitude];
        const marker = L.marker(latLng).addTo(store.map);

        marker.bindTooltip("Item Location", {
          direction: "top",
          className: "item-location-tooltip",
        });

        marker.on("click", () => this.showModal(poi));
        store.markers.push(marker);
      });
    },
    showModal(poi) {
      const store = useDataStore();
      store.setSelectedPoi(poi);
      store.setModalVisible(true);
    },
  },
};
