<template>
  <div class="scrollable-form">
    <h2 class="item-heading">Item Information</h2>

    <div class="form-group">
      <label>Select Item Categorie:</label>
      <select
        v-model="store.selectedCategory"
        :class="{ 'input-error': errors.selectedCategory }"
      >
        <option disabled value="">Select a category</option>
        <optgroup
          v-for="category in store.categories"
          :key="category.id"
          :label="category.name"
        >
          <option
            v-for="subCategory in category.subCategories"
            :key="subCategory.id"
            :value="subCategory.id"
          >
            {{ subCategory.name }}
          </option>
        </optgroup>
      </select>
      <span v-if="errors.selectedCategory" class="error-text"
        >Category is required.</span
      >
    </div>

    <div class="form-group">
      <label>Select Item Status:</label>
      <select
        v-model="selectedTag"
        :class="{ 'input-error': errors.selectedTag }"
      >
        <option value="new">New</option>
        <option value="used_like_new">Used - Like New</option>
        <option value="used">Used</option>
        <option value="parts">Parts</option>
      </select>
      <span v-if="errors.selectedTag" class="error-text"
        >Item status is required.</span
      >
    </div>

    <div class="form-group">
      <label>Add Image Of Item:</label>
      <input
        type="file"
        accept="image/*"
        @change="onFileChange"
        :class="{ 'input-error': errors.selectedFile }"
      />
      <span v-if="errors.selectedFile" class="error-text"
        >Image is required.</span
      >
      <small class="form-text text-muted"
        >Maximum image upload size is 10 MB.</small
      >
    </div>

    <div class="form-group">
      <label>Item Description:</label>
      <textarea
        v-model="store.pois[0].contentBlock.itemDescription"
        placeholder="Optional"
      ></textarea>
    </div>

    <!-- 
    <div class="form-group">
      <label for="name">Optional Name:</label>
      <input
        id="name"
        type="text"
        v-model="store.userSuppliedName"
        placeholder="Enter your name (optional)"
      />
    </div>

    <div class="form-group">
      <label for="email">Optional Email:</label>
      <input
        id="email"
        type="email"
        v-model="store.userSuppliedEmail"
        placeholder="Enter your email for a chance to win a $100 gift card"
      />
    </div>
    -->

    <button @click.prevent="submitAll" class="btn-submit">SUBMIT</button>

    <div v-if="submitting" class="spinner-modal">
      <div class="spinner-box">
        <div class="spinner"></div>
        <p>Submitting...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useDataStore } from "@/stores/dataStore";
import { s3Upload } from "@/utils/fileUpload";
import { useRouter } from "vue-router";
import api from "@/endpoints/api";
import categoriesMixin from "@/mixins/categoriesMixin";
import { db } from "@/db";
import VueCookies from "vue-cookies";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";

export default {
  mixins: [categoriesMixin],
  setup() {
    const store = useDataStore();
    const router = useRouter();
    return { store, router };
  },
  data() {
    return {
      submitting: false,
      selectedTag: "",
      errors: {
        selectedCategory: false,
        selectedTag: false,
        selectedFile: false,
      },
    };
  },
  computed: {
    isFormValid() {
      return (
        this.store.selectedCategory &&
        this.store.selectedFile &&
        this.selectedTag
      );
    },
  },
  methods: {
    onFileChange(event) {
  const file = event.target.files[0];

  if (file && file.type.startsWith("image/")) {
    this.store.setSelectedFile(file);
    this.store.setUploadID(null);
    this.errors.selectedFile = false; 
  } else {
    this.errors.selectedFile = true;
    alert("Please select a valid image file.");
    event.target.value = ""; 
  }
},
    validateForm() {
      this.errors.selectedCategory = !this.store.selectedCategory;
      this.errors.selectedTag = !this.selectedTag;
      this.errors.selectedFile = !this.store.selectedFile;

      return (
        !this.errors.selectedCategory &&
        !this.errors.selectedTag &&
        !this.errors.selectedFile
      );
    },
    async uploadFile() {
      const file = this.store.selectedFile;
      if (file && file.type.startsWith("image/")) {
        try {
          const options = {
            maxSizeMB: 10,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);
          const uploadID = await s3Upload(compressedFile);
          this.store.setUploadID(uploadID);
        } catch (err) {
          console.error("Failed to upload file: ", err);
          alert("Failed to upload file. Check console for details.");
        }
      } else {
        alert("Please select a file to upload.");
      }
    },

    async submitAll() {
      if (!this.validateForm()) {
        alert("Please fill out all required fields.");
        return;
      }

      this.submitting = true;

      if (this.store.selectedFile && !this.store.uploadID) {
        await this.uploadFile();
        console.log("File uploaded with ID: ", this.store.uploadID);
      } else {
        console.log(
          "No file uploaded, using existing upload ID: ",
          this.store.uploadID
        );
      }

      const description = this.store.pois[0].contentBlock.itemDescription;
      const tag = this.selectedTag ? ` (Item Condition: ${this.selectedTag})` : "";
      const finalDescription = tag + description ;

      // Create POI structure
      const newPOI = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            this.store.pois[0].longitude,
            this.store.pois[0].latitude,
          ],
        },
        properties: {
          map_content: this.store.pois[0].map_content,
          name: uuidv4(),
          sub_categories:
            this.store.pois[0].sub_categories.length > 0
              ? this.store.pois[0].sub_categories.map(Number)
              : [this.store.selectedCategory],
          content_block: [
            {
              language: "en",
              layout_card: 14,
              content_array: [
                {
                  reactive: 0,
                  media_type: 10,
                  media_file: this.store.uploadID,
                  field_key: "i1",
                  caption: finalDescription || "not given",
                },
              ],
            },
          ],
        },
      };

      const updatedPoi = [newPOI];
      const pass_key = VueCookies.get("pass_key");
      const id_field = VueCookies.get("id_field");

      const payload = {
        org_domain_name: this.store.payload.org_domain_name,
        org_key: this.store.payload.org_key,
        user_supplied_name: this.store.userSuppliedName.trim() || "sam",
        id_field: id_field || 0,
        pass_key: pass_key || "string",
        user_supplied_email:
          this.store.userSuppliedEmail.trim() || "user@example.com",
        map_json: {
          is_public: true,
          languages: ["en"],
          pois: updatedPoi,
        },
        post_ready: true,
      };

      console.log(
        "Final payload for submission: ",
        JSON.stringify(payload, null, 2)
      );

      const map_pk = VueCookies.get("map_pk");
      console.log("Checking for existing map with map_pk: ", map_pk);

      try {
        let response;
        if (map_pk) {
          // PATCH to update the map
          console.log("update payload", payload);
          response = await api.patch(
            `/api/anon-map-update/${map_pk}/`,
            payload
          );
          console.log("Map updated successfully: ", response.data);
        } else {
          // POST to create a new map
          response = await api.post("/api/anon-map-create/", payload);
          console.log("New map created successfully: ", response.data);

          // Store map_pk, pass_key, and id_field in cookies for future updates
          const pass_key = response?.data?.pass_key;
          if (pass_key) {
            VueCookies.set("pass_key", pass_key, "1y");
            console.log("pass_key set: ", pass_key);
          }
          const id_field = response?.data?.id_field;
          if (id_field) {
            VueCookies.set("id_field", id_field, "1y");
            console.log("id_field set: ", id_field);
          }
          const new_map_pk = response?.data?.map_pk;
          if (new_map_pk) {
            VueCookies.set("map_pk", new_map_pk, "1y");
            console.log("map_pk set: ", new_map_pk);
          }
        }

        // Handle returned POIs and update local IndexedDB or store as needed
        const pois = response?.data?.pois;
        console.log("pois", pois);
        if (pois && pois.length > 0) {
          console.log("POIs returned from server:", pois);
          const poi = pois[pois.length - 1];
          try {
            await db.pois.add({
              poi_id: poi.id,
              file_key: this.store.fileKey,
              name: poi.name,
            });
            console.log(`POI added to local DB: ${poi.name}`);
          } catch (error) {
            console.error("Failed to add POI to local database:", error);
          }
        }

        // Emit an event or handle form submission completion
        this.$emit("formSubmitted");
      } catch (error) {
        console.error("Failed to submit data:", error);
        if (error.response) {
          console.error("Error response data:", error.response.data);
        }
        alert("Failed to submit data. Check the console for more details.");
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped>
.scrollable-form {
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 10px;
}

.input-error {
  border: 1px solid red;
}

.error-text {
  color: red;
  font-size: 12px;
}

.item-heading {
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group select,
.form-group input[type="file"],
.form-group textarea,
.form-group input[type="email"],
.form-group input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #d6dbce;
  font-size: 16px;
  font-style: italic;
}

.form-group input[type="file"] {
  cursor: pointer;
}

.form-group textarea {
  height: 100px;
  resize: none;
}

.btn-submit {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #3cb317;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.spinner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner-box {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #333;
  animation: spin 1s linear infinite;
}

/* Mobile Responsive Styles */
@media (max-width: 600px) {
  .scrollable-form {
    max-height: 50vh;
    padding-right: 5px;
  }

  .form-group input,
  .form-group select,
  .btn-submit {
    font-size: 12px;
    padding: 8px;
  }

  .item-heading {
    font-size: 16px;
  }
  input::placeholder,
  .form-group textarea {
    font-size: 10.3px;
    color: #666;
  }
}
</style>
