<template>
  <v-app>
    <v-container class="fill-height d-flex align-center justify-center" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" sm="12" class="text-center">
          <v-img
            :src="image"
            alt="Valley Give&Take Logo"
            contain
            max-height="400"
            class="mb-4"
          ></v-img>
          <v-card class="pa-10" color="secondary" dark>
            <v-card-title class="display-1"
              >Welcome to Valley Give & Take</v-card-title
            >
            <v-card-text class="subtitle-1 mb-6">
              A place to give and take items for free in the Annapolis Valley
              during fall cleanup.
            </v-card-text>
            <v-btn color="primary" class="mx-2" large :to="{ name: 'MainApp' }"
              >Give</v-btn
            >
            <v-btn
              v-if="mapID"
              color="success"
              class="mx-2"
              large
              outlined
              :to="{ name: 'ViewerApp', params: { id: mapID } }"
            >
              Take
            </v-btn>
            <v-card-actions class="justify-center">
              <v-btn :to="{ name: 'UserItems' }"> Manage my items </v-btn>
              <v-btn
                href="mailto:info@strollopia.com?subject=Feedback on Valley Give and Take"
                class="mx-2"
              >
                Contact Us
              </v-btn>
            </v-card-actions>
          </v-card>

          <!-- Acknowledgment Section -->
          <v-card class="mt-4 pa-3" color="grey lighten-4">
            <v-card-text class="text-center">
              Thanks to <strong>Divert NS</strong> for funding and to our other
              partners for their support.
            </v-card-text>
            <v-card-text class="text-center">
              <div>
                If you are using this platform, you agree to the following
                <a
                  href="/terms"
                  style="text-decoration: underline; color: blue"
                >
                  Terms and Conditions
                </a>
              </div>
            </v-card-text>
          </v-card>

          <!-- New Next Project Section -->
          <v-card class="mt-4" flat>
            <v-card-text class="text-center" style="color: black">
              <div>
                <strong>Preview Our Next Positive Change Initiative </strong>

                <p>
                  <a
                    href="http://ns.flagthis.ca/"
                    target="_blank"
                    style="text-decoration: underline; color: black"
                  >
                    Risk Awareness
                  </a>
                </p>
              </div>
            </v-card-text>
          </v-card>

          <!-- Survey Section -->
          <v-card class="mt-4" flat>
            <v-card-text class="text-center" style="color: black">
              <strong
                >Fill out the following survey for a chance to win a $100 gift
                card!</strong
              >

              <a
                href="https://forms.gle/xzf77sHx7mAzw4zt9"
                target="_blank"
                style="text-decoration: underline; color: black"
              >
                Complete the Survey
              </a>
            </v-card-text>
          </v-card>

          <v-card class="mt-4" flat>
              <p>
                <a
                  href="/ValleyGiveandTakebrochure.pdf"
                  target="_blank"
                  style="text-decoration: underline; color: blue"
                >
                  About Us
                </a>
              </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import image from "@/assets/logo.png";
import { fetchPublicOrgMaps } from "@/services.js";
import { useDataStore } from "@/stores/dataStore";

export default {
  name: "LandingPage",
  data() {
    return {
      image,
      mapID: null,
    };
  },
  setup() {
    const store = useDataStore();
    return { store };
  },
  async created() {
    const orgMapdata = await fetchPublicOrgMaps();
    const previewMap = orgMapdata.data.find(
      (map) => map.map_name === "public-map"
    );
    this.mapID = previewMap.map_obj || null;
  },
};
</script>

<style scoped>
.fill-height {
  max-height: 100vh;
}
.v-col-12 {
  flex: 0 0 100%;
  max-width: 70vw;
}
.text-decoration-none {
  text-decoration: none;
  color: inherit;
}
.v-card-text {
  padding: 0.3rem;
  font-size: 1rem;
}
.v-card-title {
  white-space: normal;
}

@media (max-width: 600px) {
  .v-img {
    max-height: 200px !important;
  }
  .v-col-12 {
    flex: 0 0 100%;
    max-width: 100vw;
  }
}
</style>
