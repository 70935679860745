import api from "@/endpoints/api";
import axios from "axios";
import { useDataStore } from "@/stores/dataStore";

// Function to upload a file to S3 and process it
export async function s3Upload(file) {
  try {
    // Request a signed URL for the file upload
    const { data } = await api.get("/api/media/signed-url/", {
      params: { filename: file.name },
    });
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    const publicUploadID = data.publicUploadID;

    // Upload the file to S3 using the signed URL
    await axios.put(data.uploadURL, file, options);

    try {
      // Notify the server to process the uploaded file
      const { data } = await api.put(`/api/media/public-upload/${publicUploadID}/process/`, {
        publicUploadID,
      });
      const fileKey = data?.file_key;

      const store = useDataStore();
      store.setUploadFileKey(fileKey);
    } catch (err) {
      console.log("Error processing file: ", err);
    }

    return publicUploadID;
  } catch (err) {
    console.log("Error uploading file: ", err);
    throw err;
  }
}
