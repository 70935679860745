<template>
  <v-container class="terms-container">
    <v-card class="pa-10" outlined>
      <v-btn icon class="close-button" @click="closePage">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-title class="display-1">Terms and Conditions</v-card-title>
      <v-card-text class="terms-content">
        <p><strong>1. Acceptance of Terms</strong></p>
        <p>
          By using the Valley Give & Take platform, you agree to these terms and
          conditions. Please read them carefully.
        </p>

        <p><strong>2. User Responsibilities</strong></p>
        <p>When submitting an item, users must:</p>
        <ul>
          <li>Provide accurate information about the item</li>
          <li>Remove unclaimed items from the roadside within 7 days</li>
          <li>Not leave anything at the roadside that you wish to keep</li>
        </ul>

        <h3>3. Prohibited Items</h3>
        <p>The following items are not permitted on Valley Give & Take:</p>
        <ul>
          <li>Firearms, ammunition, and weapons</li>
          <li>Illegal drugs and drug paraphernalia</li>
          <li>Hazardous materials</li>
          <li>Counterfeit goods</li>
          <li>Recalled items</li>
          <li>Personal information</li>
          <li>Animals</li>
        </ul>

        <h3>4. Gift Card Giveaway</h3>
        <p>
          Participation in any gift card giveaway is subject to the following:
        </p>
        <ul>
          <li>Entrants must be legal residents of Nova Scotia, Canada</li>
          <li>Entrants must be of legal age (19 years or older)</li>
          <li>No purchase necessary</li>
          <li>Limit of one entry per person</li>
          <li>Winners will be selected randomly</li>
          <!-- Updated Section -->
          <li>Prizes are non-transferable</li>
          <li>
            Winners will be contacted by email and asked a skill-testing
            question
          </li>
        </ul>

        <h3>5. Email Usage</h3>
        <p>
          Your email address will only be used to send a feedback form related
          to your Valley Give & Take experience.
        </p>

        <h3>6. Liability</h3>
        <p>
          Valley Waste, Divert NS, Colibri Software, and Valley Give & Take are
          not responsible for:
        </p>
        <ul>
          <li>
            Any injuries, damages, or losses resulting from the use of the
            platform
          </li>
          <li>The condition or quality of items exchanged</li>
          <li>Any disputes between users</li>
          <li>Items left at the roadside</li>
        </ul>

        <h3>7. Indemnification</h3>
        <p>
          Users agree to indemnify and hold harmless Valley Waste, Divert NS,
          Colibri Software, or its affiliates, and Valley Give & Take from any
          claims, damages, or expenses arising from their use of the platform.
        </p>

        <h3>8. Modifications</h3>
        <p>
          We reserve the right to modify these terms and conditions at any time.
          Continued use of the platform after changes constitutes acceptance of
          the new terms.
        </p>

        <h3>9. Governing Law</h3>
        <p>
          These terms and conditions are governed by the laws of Nova Scotia,
          Canada.
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "TermsAndConditions",
  methods: {
    closePage() {
      this.$router.push({ name: "LandingPage" });
    },
  },
};
</script>

<style scoped>
.terms-container {
  position: relative;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.terms-content {
  color: black;
  font-size: 16px;
}
</style>
