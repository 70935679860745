import api from "@/endpoints/api";

// Function to get a signed download URL for a file from S3
export async function s3Download(fileKey) {
  try {
    // Make an API request to get the signed download URL for the specified file key
    const response = await api.get("/api/media/signed-download-url/", {
      params: { file_key: fileKey },
    });
    const downloadURL = response.data.downloadURL;

    return downloadURL;
  } catch (err) {
    console.error("Error fetching download URL: ", err);
  }
}
