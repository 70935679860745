<template>
  <div class="search-bar-wrapper">
    <v-row no-gutters>
      <!-- Search Input (with margin adjustment) -->
      <v-col cols="10">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search for an address"
          @keypress.enter.prevent="searchAddress"
          outlined
          dense
          solo
          class="search-input"
          color="primary"
          style="height: 56px;;" 
        />
      </v-col>

      <!-- Search Icon Button (same height as input) -->
      <v-col cols="2">
        <v-btn
          color="primary"
          large
          class="search-button"
          @click="searchAddress"
          icon
          :style="{ height: '56px' }" 
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { useDataStore } from "@/stores/dataStore";
import mapMixin from "@/mixins/mapMixin";

export default {
  mixins: [mapMixin],
  data() {
    return {
      searchQuery: "",
    };
  },
  setup() {
    const store = useDataStore();
    return { store };
  },
  methods: {
    async searchAddress() {
      const store = useDataStore();
      if (!store.map) {
        console.error("Map is not initialized");
        return;
      }

      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${this.searchQuery}`
        );
        if (response.data.length > 0) {
          const { lat, lon } = response.data[0];
          store.map.setView([lat, lon], 13); // Center the map on the searched location
          this.updateSearchMarker(parseFloat(lat), parseFloat(lon)); // Update the search marker on the map
        } else {
          alert("Address not found");
        }
      } catch (error) {
        console.error("Error searching address:", error);
      }
    },
  },
};
</script>

<style scoped>
.search-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-text-field {
  background-color: white;
}

.search-button {
  height: 56px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
