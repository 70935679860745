<template>
  <div id="map" class="map-container"></div>
</template>

<script>
import mapMixin from "@/mixins/mapMixin";

export default {
  mixins: [mapMixin],
};
</script>

  