import api from "@/endpoints/api";
import { useDataStore } from "@/stores/dataStore";

export default {
  created() {
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      const store = useDataStore();
      try {
        const response = await api.get("/api/categories/", {
          params: {
            org_domain_name: store.orgUrl,
            min: true,
          },
        });

        const subCategoryMap = {};
        const categoryMap = {};

        // Process the API response to extract categories and sub-categories
        const categories = Object.entries(response.data.categories).map(
          ([mainCategoryName, subCatsObj]) => {
            const subCategories = Object.entries(subCatsObj).map(
              ([subCatName, subCatId]) => {
                subCategoryMap[subCatId] = subCatName; // Map sub-category ID to its name
                categoryMap[subCatId] = mainCategoryName; // Map sub-category ID to its main category name
                return { name: subCatName, id: subCatId };
              }
            );
            return {
              name: mainCategoryName,
              id: mainCategoryName.toLowerCase().replace(/\s+/g, "_"),
              subCategories,
            };
          }
        );

        store.setCategories(categories);
        store.setSubCategoryMap(subCategoryMap);
        store.setCategoryMap(categoryMap);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    },
  },
};
