import api from "@/endpoints/api";

export const fetchPublicOrgMaps = async () => {
  const params = {
    owner__org_domain_name: process.env.VUE_APP_ORG_DOMAIN,
    map_name: "public-map",
  };
  const response = await api.get(
    "/api/public-org-map/", {
      params,
    }
  );
  return response;
}

// Patch Users Map
export const patchUsersMap = async (mapID, data) => {
  const response = await api.patch(
    `/api/anon-map-update/${mapID}/?org_domain_name=${process.env.VUE_APP_ORG_DOMAIN}`,
    data
  );
  return response;
}