import { defineStore } from 'pinia';
import VueCookies from 'vue-cookies';

// Function to define the initial state
const initialState = () => ({
  orgUrl: process.env.VUE_APP_ORG_DOMAIN,
  payload: {
    org_domain_name: process.env.VUE_APP_ORG_DOMAIN,
    org_key: process.env.VUE_APP_ORG_KEY,
    name: "bob",
    id_field: VueCookies.get('id_field') || null,
    pass_key: VueCookies.get('pass_key') || null,
    email: VueCookies.get('api_generated_email') || null,
    map_json: {},
  },
  tags: [""],
  map: null,
  map_pk: VueCookies.get('map_pk') || null,
  markers: [],
  pois: [
    {
      map_content: false,
      name: "hh",
      latitude: 45.0918,
      longitude: -64.3665,
      sub_categories: [],
      content_block: [],
      selectedCard: null,
      contentBlock: { itemDescription: "" }
    },
  ],
  categories: [],
  subCategoryMap: {},
  categoryMap: {},
  postableData: null,
  userSuppliedName: "",
  userSuppliedEmail: "",
  selectedCategory: null,
  selectedFile: null,
  uploadID: null,
  fileKey: "example-file-key",
  poiCounter: 1,
  maps: [],
  loading: true,
  error: null,
  isModalVisible: false,
  selectedPoi: null,
});

export const useDataStore = defineStore('data', {
  state: initialState,
  getters: {
    passKey: (state) => {
      return state.payload.pass_key;
    },
    idField: (state) => {
      return state.payload.id_field;
    },
    isBasicAuthenticated: (state) => {
      return !!(state.payload.id_field && state.payload.pass_key && state.map_pk)
    }
  },
  actions: {
    setPayload(payload) {
      this.payload = payload;
    },
    addTag(tag) {
      this.tags.push(tag);
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    addPoi(poi) {
      this.pois.push(poi);
    },
    removePoi(index) {
      this.pois.splice(index, 1);
    },
    setCategories(categories) {
      this.categories = categories;
    },
    setSubCategoryMap(map) {
      this.subCategoryMap = map;
    },
    setCategoryMap(map) { 
      this.categoryMap = map;
    },
    setPostableData(data) {
      this.postableData = data;
    },
    setUserSuppliedName(name) {
      this.userSuppliedName = name;
    },
    setUserSuppliedEmail(email) {
      this.userSuppliedEmail = email;
    },
    setSelectedCategory(category) {
      this.selectedCategory = category;
    },
    setSelectedFile(file) {
      this.selectedFile = file;
    },
    setUploadID(id) {
      this.uploadID = id;
    },
    setUploadFileKey(key) {
      this.fileKey = key;
    },
    incrementPoiCounter() {
      this.poiCounter++;
    },
    setMaps(maps) {
      this.maps = maps;
    },
    setLoading(loading) {
      this.loading = loading;
    },
    setError(error) {
      this.error = error;
    },
    setModalVisible(isVisible) {
      this.isModalVisible = isVisible;
    },
    setSelectedPoi(poi) {
      this.selectedPoi = poi;
    },
    resetStoreToDefaults() {
      // Reset the state to the initial state
      Object.assign(this.$state, initialState());
    },
  }
});
